// Import your CSS/SCSS files here:

body {
  font-family: "Raleway", sans-serif;
}
strong {
  font-weight: 700;
}
.quest {
  font-family: "Questrial", sans-serif;
}
.raleway {
  font-family: "Raleway", sans-serif;
}
h1,
.h1 {
  font-size: responsive 35px 60px !important;
  line-height: responsive 38px 65px !important;
  font-family: "Raleway", sans-serif;
}
h2,
.h2 {
  font-size: responsive 26px 45px !important;
  line-height: responsive 30px 51px !important;
  font-family: "Raleway", sans-serif;
}

h3,
.h3 {
  font-size: responsive 16px 20px !important;
  line-height: responsive 20px 25px !important;
  font-family: "Raleway", sans-serif;
}
.slide-text {
  h2 {
    font-size: responsive 35px 60px !important;
    line-height: responsive 38px 65px !important;
    font-family: "Raleway", sans-serif;
    margin-bottom: 2.5rem;
    font-weight: 700;
  }
  h3 {
    font-size: responsive 25px 35px !important;
    line-height: responsive 28px 41px !important;
    font-family: "Raleway", sans-serif;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
}
.form-group,
.mc-field-group {
  position: relative;
  input,
  textarea {
    border: 1px solid #9cb0ba;
    padding: 24px 15px 14px 15px;
    width: 100%;
    border-radius: 0.5rem !important;
    @media (max-width: 720px) {
      padding: 15px 10px 14px 15px;
      border-radius: 0.3rem !important;
    }
  }
  span {
    position: absolute;
    top: 8px;
    left: 15px;
    color: #3f3d56;
    font-size: 11px;
    z-index: 10;
    font-weight: bold;
  }
}
.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  @media (max-width: 880px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.div1 {
  grid-area: 1 / 1 / 4 / 3;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div2 {
  grid-area: 1 / 3 / 6 / 5;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div3 {
  grid-area: 1 / 5 / 5 / 7;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div4 {
  grid-area: 4 / 1 / 9 / 3;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div5 {
  grid-area: 6 / 3 / 10 / 5;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div6 {
  grid-area: 5 / 5 / 9 / 7;
  @media (max-width: 880px) {
    width: 100%;
    .img-bg {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
}
.news-wrapper {
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin-left: 1rem;
    li {
      list-style: circle;
      margin-bottom: 1rem;
    }
  }
}
.two-col-split {
  @media (min-width: 1024px) {
    -webkit-columns: 100px 2;
    /* Chrome, Safari, Opera */
    -moz-columns: 120px 2;
    /* Firefox */
    columns: 120px 2;
  }
  p {
    margin-bottom: 1rem;
  }
}
.text-block {
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
}
.blogPostList__pagination__prev {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("/images/grey-arrow-left.svg");
}
.blogPostList__pagination__pageNumber {
  font-size: 18px;
  padding: 0 10px;
  color: #c44615;
}
.fill-orange {
  fill: #c44615;
}
.stroke-white {
  stroke: #fff;
}
.pageNumberIsActive {
  color: #000;
}
.blogPostList__pagination__next {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("/images/grey-arrow-right.svg");
}
.content__gdpr {
  input {
    width: auto;
  }
  fieldset.mc_fieldset.gdprRequired.mc-field-group {
    border: 1px solid #9cb0ba;
    padding: 24px 15px 14px 15px;
    width: 100%;
    border-radius: 0.5rem !important;
  }
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }
}
.content__gdprLegal {
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }
}
#ticker-widget {
  width: 100%;
  border: none;
  overflow: hidden;
}
#header-widget {
  width: 100%;
  border: none;
  overflow: hidden;
  max-height: 30px;
  font-size: 12px;
  span {
    font-size: 12px;
  }
}
.news-wrapper {
  a {
    color: #0f56d4;
    border-bottom: 1px solid;
  }
}

.fade-in-sub {
  animation: forwards 0.3s;
  animation-name: fade-in;
  animation-delay: 3s;
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fulltext-block {
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
  }
}
.contact-details {
  h3 {
    margin-bottom: 1rem;
  }
}
.text-text-about {
  p {
    margin-bottom: 1rem;
  }
  figure {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    img {
      max-width: 200px;
    }
  }
}
