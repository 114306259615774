/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: #12519e;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 0px #c14e10;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  fill: white;
}

/* ---- previous/next buttons ---- */

.alt-block {
  .flickity-prev-next-button {
    bottom: 0% !important;
    width: 32px;
    background-color: #f0eff4;
    border: 1px solid #3f3d56;
    height: 32px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  .flickity-prev-next-button.previous {
    left: 0px !important;
    @media (max-width: 1024px) {
      left: 10px !important;
    }
  }
  .flickity-prev-next-button.next {
    right: 0px !important;
    @media (max-width: 1024px) {
      right: 10px !important;
    }
  }
}

.flickity-prev-next-button {
  margin-bottom: 80px;
  margin-right: 30px;
  bottom: 0;
  width: 77px;
  height: 77px;
  @media (max-width: 1024px) {
    width: 50px;
    margin-bottom: 30px;
    height: 50px;
    margin-right: 0px;
  }
  border-radius: 50%;
}

.flickity-prev-next-button.previous {
  background-size: contain !important;
  background: url("/images/grey-arrow-up-2.svg");
  left: auto;
  @media (max-width: 1024px) {
    right: 60px !important;
  }
  right: 100px !important;
  svg {
    display: none;
  }
}
.flickity-prev-next-button.next {
  background-size: contain !important;
  right: 0px !important;
  background: url("/images/grey-arrow-down-2.svg");
  svg {
    display: none;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  right: 100px;
  top: 0;
  width: 40%;
  height: 40%;
}

.is-selected {
  opacity: 1;
}

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.flickity-page-dots {
  position: absolute;
  top: 30px;
  right: 30px;
  @media (max-width: 1024px) {
    right: 0px;
  }
  z-index: 60;
  li {
    width: 15px;
    height: 15px;
    border-radius: 9999px;
    text-indent: -99999px;
    overflow: hidden;
    background: white;
    margin-right: 5px;
    display: inline-block;
  }
  li.is-selected {
    background: #de5b28;
  }
}
.content-slider {
  .flickity-prev-next-button {
    top: auto !important;
    bottom: 200px !important;
    width: 77px;
    height: 77px;
    @media (max-width: 1024px) {
      width: 50px;
      bottom: auto !important;
      top: 20px !important;
      height: 50px;
    }
  }
  .flickity-prev-next-button.previous {
    @media (max-width: 1024px) {
      left: 40px;
    }
    background: url("/images/grey-arrow-up-2.svg");
    left: 0px;
    svg {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    left: 100px;
    @media (max-width: 1024px) {
      left: 100px;
    }
    background: url("/images/grey-arrow-down-2.svg");
    svg {
      display: none;
    }
  }
}

.slider-new {
  .flickity-prev-next-button {
    top: auto !important;
    bottom: 20px !important;
    width: 77px;
    height: 77px;
    @media (max-width: 1024px) {
      width: 50px;
      bottom: auto !important;
      top: 20px !important;
      height: 50px;
    }
  }
  .flickity-prev-next-button.next {
    @media (max-width: 1024px) {
      right: auto !important;
      left: 100px;
    }
    right: 0px !important;
    left: auto;
    background: url("/images/grey-arrow-down-2.svg");
    svg {
      display: none;
    }
  }
  .flickity-prev-next-button.previous {
    @media (max-width: 1024px) {
      right: auto !important;
      left: 40px;
    }
    background: url("/images/grey-arrow-up-2.svg");
    right: 100px !important;
    left: auto;
    svg {
      display: none;
    }
  }
}
